import React, { useState, useEffect, createContext, useContext } from "react"
import PropTypes from "prop-types"
import ContainerSite from "../3_layouts/ContainerSite"
import Navigation from "../2_sections/Navigation"
import Footer from "../2_sections/Footer"
import TransitionPageIn from "../0_transitions/TransitionPageIn.js"
import ScrollToTopButton from "../1_components/ScrollToTopButton"
import BackgroundPattern from "../5_assets/static/BackgroundPattern.js"
import FadeOutOnScroll from "../0_transitions/FadeOutOnScroll.js"

export const DarkThemeContext = createContext()

const Layout = ({ children, location }) => {
   const [darkMode, setDarkMode] = useState(true)

   useEffect(() => {
      const isBrightPage = ["/project/", "/article/"].some((path) => location.pathname.startsWith(path))
      setDarkMode(!isBrightPage)
   }, [location.pathname])

   const toggleDarkMode = () => {
      setDarkMode((prevMode) => !prevMode)
   }

   const isBrightPage = ["/project/", "/article/"].some((path) => location.pathname.startsWith(path))

   return (
      <DarkThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
         <div
            className={`relative h-full w-full overflow-hidden text-colour bg-surface_bg_8 dark:bg-surface_bg_0  ${
               darkMode ? "dark" : ""
            }`}
         >
            <FadeOutOnScroll>{!isBrightPage && <BackgroundPattern />}</FadeOutOnScroll>

            <Navigation />
            <TransitionPageIn>
               <main>
                  <ContainerSite>{children}</ContainerSite>
               </main>
            </TransitionPageIn>
            <div className="fixed bottom-[8%] right-[6%] flex space-x-2 p-7 pb-0 pr-0">
               <ScrollToTopButton />
            </div>
            <Footer />
         </div>
      </DarkThemeContext.Provider>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
   location: PropTypes.object.isRequired,
}

export default Layout
