import React from "react"
import { Link } from "gatsby"

function Button({
   linkTo,
   href,
   label,
   title,
   iconLeft,
   iconRight,
   size = "sm", // xs,sm,md
   containerCustomClasses,
   textCustomClasses,
   onClick,
   isRounded = true,
   isPrimaryDark = true,
   hasDot = true,
   hasShadow = true,
}) {
   const containerDefaultClasses = `group/button flex items-center space-x-1.5 inline-block text-center ${
      hasShadow && "drop-shadow-xl"
   } ${
      isPrimaryDark
         ? "bg-surface_bg_3 hover:bg-surface_bg_4 border-1 border-borderBright"
         : "bg-surface_bg_2 hover:bg-surface_bg_4 border-1 border-borderDark"
   }
   ${
      size === "xs"
         ? "pb-1.5 pt-1 pr-3.5 pl-3.5"
         : size === "sm"
           ? "py-1 pr-3 pl-3"
           : size === "md"
             ? "py-2 px-4 3md:pl-6 3md:pr-6 py-3"
             : ""
   }
   ${isRounded ? "rounded-full" : "border-radius-sm"}
   ${containerCustomClasses}
`

   const iconDefaultClasses = `text-inherit ${
      size === "xs"
         ? "w-5 md:w-5 text-inherit"
         : size === "sm"
           ? "w-7 md:w-7 text-inherit"
           : size === "md"
             ? "w-7 md:w-7 text-inherit"
             : "w-6 md:w-6 text-inherit"
   }`

   const textDefaultClasses = `body !tracking-tight group-hover/button:underlined md:text-nowrap ${
      isPrimaryDark ? "text-body__Bright" : "text-body__Bright"
   } ${size === "xs" ? "!text-sm" : size === "sm" ? "!text-base" : size === "md" ? "!text-md mb-0.5" : ""}`

   const renderDot = (size = "md") => (
      <div
         class={`relative inline-flex items-center  ${
            size === "xs"
               ? "w-2 h-2 mt-0.5 mr-0.5"
               : size === "sm"
                 ? "w-3 h-3 mr-2"
                 : size === "md"
                   ? "w-2.5 h-2.5 mr-2"
                   : ""
         }`}
      >
         <div
            class={`bg-teal-300 rounded-full ${
               size === "xs" ? "w-2 h-2" : size === "sm" ? "w-3 h-3" : size === "md" ? "w-2.5 h-2.5" : ""
            }`}
         ></div>
         <div
            class={`bg-teal-300 rounded-full absolute top-0 left-0 animate-ping ${
               size === "xs" ? "w-2 h-2" : size === "sm" ? "w-3 h-3" : size === "md" ? "w-2.5 h-2.5" : ""
            } "rounded-full"`}
         ></div>
         <div
            class={`bg-teal-300 rounded-full absolute top-0 left-0 animate-pulse ${
               size === "xs" ? "w-2 h-2" : size === "sm" ? "w-3 h-3" : size === "md" ? "w-2.5 h-2.5" : ""
            } "rounded-full"`}
         ></div>
      </div>
   )

   const ButtonComponent = (
      <button className={containerDefaultClasses} title={label} aria-label={title} role="button" onClick={onClick}>
         {hasDot && renderDot(size)}
         <p className={`${textDefaultClasses} ${textCustomClasses}`}>{label}</p>
         {iconRight && <span className={`${textDefaultClasses} ${iconDefaultClasses} `}>{iconRight}</span>}
      </button>
   )

   if (linkTo) {
      return <Link to={linkTo}>{ButtonComponent}</Link>
   } else if (href) {
      return (
         <a rel="noopener noreferrer" target="_blank" href={href}>
            {ButtonComponent}
         </a>
      )
   } else if (!href || !linkTo === null) {
      return <div>{ButtonComponent}</div>
   }
}

export default Button
