import React from "react"
import PatternImg from "../../5_assets/svg/pattern_2.svg"
import FadeOutOnScroll from "../../0_transitions/FadeOutOnScroll.js"

function BackgroundPattern({ height = "40rem", fadeColor = "offWhite" }) {
   const darkModeClass = fadeColor === "offWhite" ? "dark:from-surface_bg_0 dark:via-surface_bg_0/20 " : ""

   return (
      <div
         className={`h-[${height}] fixed -z-100
             transform left-1/2 -translate-x-1/2 overflow-y-hidden
             -top-[10rem] xs:-top-[18rem] md:-top-[10rem] lg:-top-[0rem] xl:-top-[0rem]
             w-[200%] xxs:w-[200%] xs:w-[250%] sm:w-[170%] md:w-[180%] lg:w-[100%] xl:w-[130%]
             opacity-100 dark:opacity-[30%] ${darkModeClass}`}
      >
         <span
            className={`absolute bottom-0 inset-0
                    bg-gradient-to-t
                    from-${fadeColor}
                    via-${fadeColor}/40
                    to-transparent ${darkModeClass}`}
         ></span>
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
